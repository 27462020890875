import React from "react"
import ImageMeta from "../../components/ImageMeta"
import Layout from "../../components/layout"
import Seamless from "../../components/Seamless"
import { SharedStateProvider } from "../../components/SharedState/PageSharedState"

import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import CTA from "../../components/CTA/CTA"
import SEO from "../../components/seo"
import TextPhoto from "../../components/TextPhoto/TextPhoto"
import { graphql } from "gatsby"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"
import { Link } from "gatsby"
import { instructions } from "../../cms/collections/instructions"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

// Only is set up for 2 or 5 docs
function MeetOurDoctors(props) {
  const data = props.post.meetOurDoctors
  // const docLength = data.images.length

  return (
    <section className="color-back p-section doc-info">
      <div className="columns">
        <div className="column is-4"></div>
        <div
          className="column has-text-centered-tablet"
          dangerouslySetInnerHTML={createHtml(converter.makeHtml(data.text))}
        ></div>
        <div className="column is-4"></div>
      </div>

      {data.buttons &&
        <div className="columns">
          <div className="column is-8"></div>
          <div className="column"><ButtonGroupMap isCentered buttons={data.buttons} /></div>
          <div className="column is-8"></div>
        </div>
      }
      {/* <div className={`columns our-docs is-multiline has-${docLength}-docs`} style={{ marginTop: "3.5rem" }}>
        <div className="column is-2"></div>
        {docLength === 1 && (
          <>
            <div className="column"></div>
            {data.images.map((image, i) => (
              <>
                <div key={image.doctor.url} className="column is-7">
                  <Link to={image.doctor.url}>
                    <ImageMeta
                      cloudName="nuvolum"
                      publicId={image.doctor.imageId}
                      width="auto"
                      responsive
                      className="meet-our-doctor"
                    ></ImageMeta>
                  </Link>
                  <Link to={image.doctor.url}>
                    <h5 className="image-caption doctor">
                      {image.doctor.caption}
                    </h5>
                  </Link>
                </div>
              </>
            ))}
            <div className="column"></div>
          </>
        )}
        <div className="column">
          <div className="columns is-multiline even-doctors">
            {props.columns === 2 && (
              <>
                {data.images.map((image, i) => {
                  let doctorUrl = image.doctor.url;
                  if (props.language === "es") {
                    doctorUrl = "/" + doctorUrl
                  }
                  return (
                    <>
                      <div key={image.doctor.url} className="column is-12">
                        <Link to={doctorUrl}>
                          <ImageMeta
                            cloudName="nuvolum"
                            publicId={image.doctor.imageId}
                            width="auto"
                            responsive
                            className="meet-our-doctor"
                          ></ImageMeta>
                        </Link>
                        <Link to={doctorUrl}>
                          <h5 className="image-caption doctor">
                            {image.doctor.caption}
                          </h5>
                        </Link>
                      </div>
                    </>
                  )
                }
                )}
              </>
            )}
          </div>
        </div>
        {docLength === 3 && (
          <>
            <div className="column is-2"></div>
            {data.images.map((image, i) => (
              <>
                <div key={image.doctor.url} className="column">
                  <Link to={image.doctor.url}>
                    <ImageMeta
                      cloudName="nuvolum"
                      publicId={image.doctor.imageId}
                      width="auto"
                      responsive
                      className="meet-our-doctor"
                    ></ImageMeta>
                  </Link>
                  <Link to={image.doctor.url}>
                    <h5 className="image-caption doctor">
                      {image.doctor.caption}
                    </h5>
                  </Link>
                </div>
                {i < 2 && <div className="column is-1"></div>}
              </>
            ))}
            <div className="column is-2"></div>
          </>
        )}
        {docLength === 5 && (
          <>
            {data.images.map((image, i) => {
              return (
                <>
                  {i % 2 === 0 && <div className="column is-4"></div>}
                  <div
                    key={image.doctor.url}
                    className="column is-7 doc-column"
                  >
                    <Link to={image.doctor.url}>
                      <ImageMeta
                        cloudName="nuvolum"
                        publicId={image.doctor.imageId}
                        width="auto"
                        responsive
                        className="meet-our-doctor"
                      ></ImageMeta>
                    </Link>
                    <Link to={image.doctor.url}>
                      <h5 className="image-caption doctor">
                        {image.doctor.caption}
                      </h5>
                    </Link>
                  </div>
                  {i % 2 === 0 && <div className="column is-1"></div>}
                  {i % 2 === 1 && <div className="column is-5"></div>}
                </>
              )
            })}
          </>
        )}
        <div className="column is-2"></div>
      </div> */}
    </section>
  )
}

export default MeetOurDoctors
