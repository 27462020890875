import React from "react"
import ImageMeta from "../../components/ImageMeta"
import Layout from "../../components/layout"
import Seamless from "../../components/Seamless"
import { SharedStateProvider } from "../../components/SharedState/PageSharedState"

import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import CTA from "../../components/CTA/CTA"
import SEO from "../../components/seo"
import TextPhoto from "../../components/TextPhoto/TextPhoto"
import { graphql } from "gatsby"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"
import { Link } from "gatsby"
import { instructions } from "../../cms/collections/instructions"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

function LearnMoreCTA(props) {
  return (
    <section
      className="body-sections section"
      style={{ paddingBottom: "80px" }}
    >
      <div className="columns has-text-centered-tablet">
        <div className="column is-5"></div>
        <div className="column">
          <h3 style={{ marginBottom: "1.5rem" }}>
            {props.post.learnMore.heading}
          </h3>
        </div>
        <div className="column is-5"></div>
      </div>
      <div className="columns has-text-centered-tablet">
        <div className="column is-4"></div>
        <div className="column mobile-col">
          <p>{props.post.learnMore.blurb}</p>
        </div>
        <div className="column is-4"></div>
      </div>

      <div className="columns">
        <div className="column is-4"></div>
        <div className="column">
          <ButtonGroup isCentered noReverse>
            <Button
              buttonText={props.post.learnMore.buttonOne.buttonText}
              href={props.post.learnMore.buttonOne.href}
              
            />
            <Button
              buttonText={props.post.learnMore.buttonTwo.buttonText}
              href={props.post.learnMore.buttonTwo.href}
              
            />
          </ButtonGroup>
        </div>
        <div className="column is-4"></div>
      </div>
    </section>
  )
}

export default LearnMoreCTA
