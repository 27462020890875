import PropTypes from "prop-types"
import React from "react"


class Seamless extends React.Component {

  constructor(props) {
    super(props);
   }

    componentDidMount() {
        const script = document.createElement("script");

        script.src = this.props.src;
        script.async = true;

        document.body.appendChild(script);


        // script.addEventListener('load', () => {
        //     if(true) {
        //         var i5e0bb52ba9e26 = new OdoiFrame("CO19121000133252663" ,"", content, "Get Started");

        //         // i5e0bb52ba9e26.init();
        //   }
        // });
    }

    render() {
      return (
          <div className="seamless-form-wrapper" id="seamless-form-wrapper">
              
          </div>
      )
    }
  }

export default Seamless