import React from "react"
import ImageMeta from "../../components/ImageMeta"
import { icon } from "@fortawesome/fontawesome-svg-core"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()
var classNames = require("classnames")

//https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_auto/v1/FLSD/DEV/wifi-icon

const returnSVG = num => {
  let icon;

  if (num === 1) {
    icon = "wifi-icon"
  } else if (num === 2) {
    icon = "family-friendly-icon"
  } else if (num === 3) {
    icon = "affordable-icon"
  } else if (num === 4) {
    icon = "safety-first-icon"
  } else if (num === 5) {
    icon = "we-speak-spanish-icon"
  } else if (num === 6) {
    icon = "judgment-free-icon"
  }

  return "/FLSD/DEV/" + icon;
}

function ReasonsWhyItem(props) {
  return (
    <div className="reasons-why-item columns">
      <div className="column is-4 is-hidden-mobile">
        <span className="reason-number-circle">
          {/* {props.number} */}
          <ImageMeta
            noLazyload
            cloudName="nuvolum"
            className="reason-svg"
            publicId={returnSVG(props.number)}
            width="auto"
            responsive
          />
        </span>
      </div>

      <div className="column">
        {props.heading}
        {props.blurb}
      </div>
    </div>
  )
}

function ReasonsWhyColumn(props) {
  return <div className="reasons-why-column column">{props.children}</div>
}

function ReasonsWhy(props) {
  var reasonsClassNames = classNames({
    "p-section reasons-why-section": true,
    "color-back": !props.invert,
    "invert-link-color": props.invert
  })

  return (
    <div className={reasonsClassNames}>

      <div className="section-divider" style={{marginBottom: "60px"}}></div>

      <div className="columns">
        <div className="column is-5"></div>
        <div
          className="column"
          dangerouslySetInnerHTML={createHtml(
            converter.makeHtml(props.post.reasonsWhySection.heading)
          )}
        ></div>
        <div className="column is-5"></div>
      </div>

      <div className="columns is-desktop">
        <div className="column is-4"></div>

        {props.post.reasonsWhySection.reasonsWhyColumn.map((column, i) => (
          <>
            <ReasonsWhyColumn key={i}>
              {column.map(reason => (
                <ReasonsWhyItem
                  key={reason.reason.number}
                  number={reason.reason.number}
                  heading={
                    <div className="reason-number-heading">
                      <span className="reason-number-circle mobile-in-heading">
                        {/* {reason.reason.number} */}
                        <ImageMeta
                          noLazyload
                          cloudName="nuvolum"
                          publicId={returnSVG(reason.reason.number)}
                          width="auto"
                          responsive
                        />
                      </span>
                      <h5>{reason.reason.heading}</h5>
                    </div>
                  }
                  blurb={
                    <div className="reasons-why-blurb"
                      dangerouslySetInnerHTML={createHtml(
                        converter.makeHtml(reason.reason.blurb)
                      )}
                    ></div>
                  }
                />
              ))}
            </ReasonsWhyColumn>
            {i === 0 && <div className="column is-1"></div>}
          </>
        ))}
        <div className="column is-4"></div>
      </div>

      <div className="section-divider" style={{marginTop: "40px"}}></div>

    </div>
  )
}

export default ReasonsWhy
